.radio {
  display: flex;
  list-style: none;
  gap: 8px;
  padding: 0;

  > li {
    cursor: pointer;
    padding: 10px;
    color: rgba(255, 255, 255, 0.75);

    &.active {
      background-color: rgba(124, 252, 0, 0.5);
      color: #fff;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    }
  }
}

button {
  font-family: EightBit, sans-serif;
  cursor: pointer;

  .icon {
    background-color: #999;
  }

  &.action {
    justify-content: center;
    background-color: rgba(124, 252, 0, 0.5);
    color: rgba(255, 255, 255, 0.75);
    width: 100%;

    font-size: 16px;
    line-height: 24px;
    display: flex;
    gap: 12px;
    padding: 6px;
    border: 1px solid #999;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  &.menu {
    width: 36px;
    height: 36px;
    cursor: pointer;
    opacity: 1;
    mask-size: 24px 24px;
    mask-position: 50% 50%;
    mask-repeat: no-repeat;
    position: absolute;
    bottom: -2px;

    &.settings {
      right: 0;
      mask-image: url('../assets/images/settings.svg');
    }

    &.share {
      left: 0;
      mask-image: url('../assets/images/share.svg');
    }
  }
}

.icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  cursor: pointer;
  mask-size: cover;
  background-color: #999;

  &.icon-share {
    mask-image: url('../assets/images/share.svg');
  }
}
