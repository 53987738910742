header {
  font-size: 14px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  div {
    padding: 8px;
    > span {
      display: block;
      margin-top: 10px;
      font-size: 12px;
    }
  }

  h1 {
    flex-grow: 1;
    display: block;
    font-size: 24px;
    opacity: 0.9;

    span {
      display: inline-block;
      width: 5px;
    }
  }
}
