@font-face {
  font-family: EightBit;
  src: url('../assets/fonts/8bit.ttf');
}

@font-face {
  font-family: LatoBold;
  src: url('../assets/fonts/LatoBold.ttf');
}

* {
  box-sizing: border-box;
}

body {
  font-family: EightBit, serif;
  color: #ffffff;
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  justify-items: center;
}

#bg {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../assets/images/bg.jpg');
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.75);
  background-blend-mode: color;
}

#app {
  position: relative;
  display: flex;
  align-self: center;
  width: 100%;
  height: 100%;
  max-width: 640px;
  max-height: 1280px;
}

.container {
  position: relative;

  .ios & {
    margin-top: 25px;
  }

  width: 100%;
  display: flex;

  flex-direction: column;
  align-items: center;

  .landscape & {
    flex-direction: row;
  }
}

@import 'elements';
@import 'header';
@import 'main';
@import 'footer';
@import 'settings';
