@font-face {
  font-family: EightBit;
  src: url("8bit.492382e8.ttf");
}

@font-face {
  font-family: LatoBold;
  src: url("LatoBold.f53d8879.ttf");
}

* {
  box-sizing: border-box;
}

body {
  color: #fff;
  justify-content: center;
  justify-items: center;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: EightBit, serif;
  display: flex;
}

#bg {
  background-blend-mode: color;
  background-color: #000000bf;
  background-image: url("bg.b17755a6.jpg");
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: absolute;
  inset: 0;
}

#app {
  align-self: center;
  width: 100%;
  max-width: 640px;
  height: 100%;
  max-height: 1280px;
  display: flex;
  position: relative;
}

.container {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.ios .container {
  margin-top: 25px;
}

.landscape .container {
  flex-direction: row;
}

.radio {
  gap: 8px;
  padding: 0;
  list-style: none;
  display: flex;
}

.radio > li {
  cursor: pointer;
  color: #ffffffbf;
  padding: 10px;
}

.radio > li.active {
  color: #fff;
  background-color: #7cfc0080;
  box-shadow: 0 4px 8px #00000026;
}

button {
  cursor: pointer;
  font-family: EightBit, sans-serif;
}

button .icon {
  background-color: #999;
}

button.action {
  color: #ffffffbf;
  background-color: #7cfc0080;
  border: 1px solid #999;
  justify-content: center;
  gap: 12px;
  width: 100%;
  padding: 6px;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  box-shadow: 0 4px 8px #00000026;
}

button.menu {
  cursor: pointer;
  opacity: 1;
  width: 36px;
  height: 36px;
  position: absolute;
  bottom: -2px;
  -webkit-mask-position: 50%;
  mask-position: 50%;
  -webkit-mask-size: 24px 24px;
  mask-size: 24px 24px;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

button.menu.settings {
  right: 0;
  -webkit-mask-image: url("settings.c39f7674.svg");
  mask-image: url("settings.c39f7674.svg");
}

button.menu.share {
  left: 0;
  -webkit-mask-image: url("share.3aede4af.svg");
  mask-image: url("share.3aede4af.svg");
}

.icon {
  cursor: pointer;
  background-color: #999;
  width: 24px;
  height: 24px;
  display: inline-block;
  -webkit-mask-size: cover;
  mask-size: cover;
}

.icon.icon-share {
  -webkit-mask-image: url("share.3aede4af.svg");
  mask-image: url("share.3aede4af.svg");
}

header {
  text-align: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 14px;
  display: flex;
}

header div {
  padding: 8px;
}

header div > span {
  margin-top: 10px;
  font-size: 12px;
  display: block;
}

header h1 {
  opacity: .9;
  flex-grow: 1;
  font-size: 24px;
  display: block;
}

header h1 span {
  width: 5px;
  display: inline-block;
}

main {
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

#boardContainer {
  background-color: #00000080;
  border: 3px solid #ffffff40;
  box-shadow: 0 4px 8px #00000080;
}

#nextPillContainer {
  opacity: .75;
  text-align: center;
  width: 100%;
  margin-bottom: 8px;
  position: relative;
}

footer {
  min-height: 108px;
}

.modal {
  color: #ffffffbf;
  z-index: 1001;
  background-blend-mode: color;
  background-color: #000000bf;
  background-image: url("bg.b17755a6.jpg");
  background-size: cover;
  display: block;
  position: absolute;
  inset: 0;
  overflow: auto;
}

.modal .close {
  color: #fff;
  text-align: center;
  width: 48px;
  height: 48px;
  font-size: 28px;
  font-weight: bold;
  line-height: 48px;
  position: absolute;
  top: 0;
  right: 0;
}

.modal .close:hover, .modal .close:focus {
  color: #000;
  cursor: pointer;
  text-decoration: none;
}

.modal .content {
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 48px;
  display: flex;
}

.modal .content .title {
  color: #7cfc0080;
  margin-top: 24px;
}

.modal .content .actions {
  margin-top: 24px;
}

/*# sourceMappingURL=index.16c23e07.css.map */
