.modal {
  color: rgba(255, 255, 255, 0.75);
  display: block;
  position: absolute;
  z-index: 1001;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.75);
  background-image: url('../assets/images/bg.jpg');
  background-size: cover;
  background-blend-mode: color;

  .close {
    position: absolute;
    right: 0;
    top: 0;
    width: 48px;
    height: 48px;
    color: #ffffff;
    font-size: 28px;
    line-height: 48px;
    text-align: center;
    font-weight: bold;

    &:hover,
    &:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 48px;
    justify-content: center;
    height: 100%;
    box-sizing: border-box;

    .title {
      margin-top: 24px;
      color: rgba(124, 252, 0, 0.5);
    }

    .actions {
      margin-top: 24px;
    }
  }
}
